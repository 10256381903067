import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '/@/pages/resource/square/layout/index.vue'
import { base } from '/@/config'
import { setupWechatJsApi } from '../utils/wechat/jsapi.ts'
import { App } from 'vue'
import { useSeoMeta } from '@unhead/vue'

export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('/@/pages/public/login/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/login/qr',
    name: 'QrLogin',
    component: () => import('/@/pages/public/login/qr/index.vue'),
    meta: {
      title: '二维码登录',
      icon: 'home-2-line',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('/@/pages/public/error/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: 'Error403',
    component: () => import('/@/pages/public/error/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('/@/pages/public/error/index.vue'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Index',
    component: () => import('/@/pages/index/index.vue'),
    meta: {
      title: '首页',
      icon: 'home-2-line',
    },
  },
  {
    path: '/open',
    name: 'OpenRoot',
    component: Layout,
    meta: {
      title: '前往',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'wechat/miniprogram',
        name: 'OpenWechatMiniProgram',
        component: () => import('/@/pages/public/open/wechat-mini-program/index.vue'),
        meta: {
          title: '前往微信小程序',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/resource/package',
    name: 'ResourcePackageRoot',
    component: Layout,
    meta: {
      title: '资源包',
      icon: 'home-2-line',
    },
    children: [
      {
        path: '/resource/package/:id',
        name: 'ResourcePackageIndex',
        component: () => import('/@/pages/resource/package/index/index.vue'),
        meta: {
          title: '资源包详情',
          icon: 'home-2-line',
          hideBottomTabBar: true,
        },
      },
    ],
  },
  {
    path: '/resource/square',
    name: 'ResourceSquareIndex',
    component: Layout,
    meta: {
      title: '资源广场',
      icon: 'home-2-line',
    },
    children: [
      {
        path: 'recommend',
        name: 'ResourceSquareRecommendIndex',
        component: () => import('/@/pages/resource/square/recommend/index.vue'),
        meta: {
          title: '资源广场·推荐',
          icon: 'home-2-line',
        },
      },
      {
        path: 'latest',
        name: 'ResourceSquareLatestIndex',
        component: () => import('/@/pages/resource/square/latest/index.vue'),
        meta: {
          title: '资源广场·最新',
          icon: 'home-2-line',
        },
      },
      {
        path: 'collect',
        name: 'ResourceSquareCollectIndex',
        component: () => import('/@/pages/resource/square/collect/index.vue'),
        meta: {
          title: '资源广场·收藏',
          icon: 'home-2-line',
        },
      },
      {
        path: 'search',
        name: 'ResourceSquareSearchIndex',
        component: () => import('/@/pages/resource/square/search/index.vue'),
        meta: {
          title: '资源广场·搜索',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'NotFound',
    meta: {
      title: '404',
      hidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(base),
  routes: constantRoutes,
})

const addRouter = (routes: RouteRecordRaw[]) => {
  routes.forEach((route: RouteRecordRaw) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export const setupRouter = (app: App<Element>) => {
  addRouter(constantRoutes)
  addRouter(asyncRoutes)
  app.use(router)

  router.beforeEach((to, from, next) => {
    next()
    setupWechatJsApi(app)

    const title = (to.meta.title || '') + ' --CC闪传资源广场'
    useSeoMeta({
      title,
      description: '',
      ogDescription: '',
      twitterDescription: '',
      ogTitle: title,
      twitterTitle: title,
      ogImage: '',
      twitterImage: '',
      articleAuthor: 'CC闪传',
      twitterSite: 'CC闪传资源广场',
      ogSiteName: 'CC闪传资源广场',
      ogUrl: router.resolve(to).href,
      ogType: 'website',
      twitterCreator: 'CC闪传',
      twitterCard: 'app',
    })
  })

  return router
}

export default router
